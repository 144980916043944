// Imports: Dependencies
import React from 'react'

// Component: Footer
const Footer = () => (
  <div id="footer">
    <a id="footer-text" href="https://github.com/jefelewis" target="_blank" rel="noopener noreferrer">Built by Jeff Lewis</a>
  </div>
);

// Exports
export default Footer;
