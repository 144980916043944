// Imports: Dependencies
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';

// Imports: Data
const ballotsReceivedByDay = require('../data/ballotsReceivedByDay_10.15.2020.json');

// Date Options
const dateOptions = {
  day: 'numeric',
  month: 'numeric',
  timeZone: 'UTC',
};

// Component: Orange County Mail In Ballots Received (Daily)
const OrangeCountyMailInBallotsReceivedDaily = () => {
  // React Hooks: State
  const [ labels, setLabels ] = useState<Array<string>>([]);

  const [ data2020, setData2020 ] = useState<Array<number>>([]);
  const [ data2018, setData2018 ] = useState<Array<number> | null>([]);
  const [ data2016, setData2016 ] = useState<Array<number> | null>([]);
  const [ data2014, setData2014 ] = useState<Array<number> | null>([]);

  // React Hooks: Lifecycle Methods
  useEffect(() => {
    // Check If Data Exists
    if (ballotsReceivedByDay.length >= 1) {
      // All Labels
      let allLabels: Array<string> = [];

      // All Data
      let all2020Data: Array<number> = [];
      let all2018Data: Array<number | null> = [null];
      let all2016Data: Array<number | null> = [null];
      let all2014Data: Array<number | null> = [null];

      // Iterate Over Data
      for (let ballots of ballotsReceivedByDay) {
        // 2020
        if (ballots.year === 2020) {
          // Iterate Over Data
          for (let i = 0; i < ballots.days_received.length - 1; i++) {
            // Formatted Date (Format: 9/25 | 10/7)
            // const formattedDate: string = new Date(ballots.days_received[i + 1].ballots_received_day).toLocaleDateString('en-US', dateOptions);

            // Add To All Labels
            // allLabels.push(formattedDate);
            allLabels.push(ballots.days_received[i + 1].days_before_election);

            // Add To All Data
            all2020Data.push(ballots.days_received[i + 1].ballots_received - ballots.days_received[i].ballots_received);
          }
        }
         // 2018
        if (ballots.year === 2018) {
          // Iterate Over Data
          for (let i = 0; i < ballots.days_received.length - 1; i++) {
            // Skip First Date
            if (i >= 1) {
              // Add To All Data
              all2018Data.push(ballots.days_received[i + 1].ballots_received - ballots.days_received[i].ballots_received);
            }
          }
        }
        // 2016
        if (ballots.year === 2016) {
          // Iterate Over Data
          for (let i = 0; i < ballots.days_received.length - 1; i++) {
            // Skip First Date
            if (i >= 1) {
              // Add To All Data
              all2016Data.push(ballots.days_received[i + 1].ballots_received - ballots.days_received[i].ballots_received);
            }
          }
        }
        // 2014
        if (ballots.year === 2014) {
          // Iterate Over Data
          for (let i = 1; i < ballots.days_received.length - 1; i++) {
            // Skip First Date
            if (i >= 1) {
              // Add To All Data
              all2014Data.push(ballots.days_received[i + 1].ballots_received - ballots.days_received[i].ballots_received);
            }
          }
        }
      }

      // Set Labels
      setLabels(allLabels);

      // Set Data
      setData2020(all2020Data);
      setData2018(all2018Data);
      setData2016(all2016Data);
      setData2014(all2014Data);
    }
  }, [ballotsReceivedByDay]);

  // ChartJS: Voter Data
  const voterData = {
    labels: labels,
    datasets: [
      {
        label: '2020 Presidential Election',
        data: data2020,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#007AFF',
        borderColor: '#007AFF',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#007AFF',
        pointBackgroundColor: '#007AFF',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#007AFF',
        pointHoverBorderColor: '#007AFF',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: '2018 Midterm Election',
        data: data2018,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#4E78A0',
        borderColor: '#4E78A0',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#4E78A0',
        pointBackgroundColor: '#4E78A0',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#4E78A0',
        pointHoverBorderColor: '#4E78A0',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: '2016 Presidential Election',
        data: data2016,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#113B5E',
        borderColor: '#113B5E',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#113B5E',
        pointBackgroundColor: '#113B5E',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#113B5E',
        pointHoverBorderColor: '#113B5E',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: '2014 Midterm Election',
        data: data2014,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#009ACD',
        borderColor: '#009ACD',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#009ACD',
        pointBackgroundColor: '#009ACD',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#009ACD',
        pointHoverBorderColor: '#009ACD',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
    ],
  };

  return (
    <div id="graph-container">
      <div id="graph-line-chart-title-container">
        <p id="graph-line-chart-title">Daily Mail In Ballots Received</p>
        <p id="graph-line-chart-subtitle">| Orange County</p>
      </div>

      <p id="increase-text">The daily number of Mail In Ballots the Orange County Registrar of Voters has received.</p>

      <Line
        data={voterData}
        height={160}
        options={{
          // maintainAspectRatio: true,
          // responsive: true,
          scaleBeginAtZero: true,
          legend: {
            position: 'bottom',
          },
          plugins: {
            datalabels: {
              display: false,
              color: '#7D7D7D',
              anchor: 'end',
              align: 'top',
              offset: 0,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                // Check If Value Exists
                if (tooltipItem.yLabel) {
                  return `${tooltipItem.yLabel.toLocaleString()} Ballots`;
                }
              },
            },
          },
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: true,
              },
            }],
            yAxes: [{
              ticks: {
                autoSkip: true,
                callback: (value: number) => {
                  // Check If Value Exists
                  if (value) {
                    return value.toLocaleString();
                  }
                },
                min: 0,
                beginAtZero: true,
                stepSize: 0,
              },
            }],
          },
        }}
      />
    </div>
  );
};

// Exports
export default OrangeCountyMailInBallotsReceivedDaily;
