// Imports: Dependencies
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';

// Imports: Data
const primaryElectionData = require('../data/primaryElectionData_10.14.2020.json');
const generalElectionData = require('../data/generalElectionData_10.14.2020.json');

// Component: California Voter Registration
const CaliforniaVoterRegistration = () => {
  // React Hooks: State
  const [ dropdownSetting, setDropdownSetting ] = useState<string>('General Election');

  const [ presidentialPrimaryEligibleTurnoutData, setPresidentialPrimaryEligibleTurnoutData ] = useState<Array<number>>([]);
  const [ presidentialPrimaryRegisteredTurnoutData, setPresidentialPrimaryRegisteredTurnoutData ] = useState<Array<number>>([]);

  const [ presidentialGeneralEligibleTurnoutData, setPresidentialGeneralEligibleTurnoutData ] = useState<Array<number>>([]);
  const [ presidentialGeneralRegisteredTurnoutData, setPresidentialGeneralRegisteredTurnoutData ] = useState<Array<number>>([]);

  // Dates
  const primaryElectionDates: Array<number> = [1916, 1920, 1924, 1928, 1932, 1936, 1940, 1944, 1948, 1952, 1956, 1960, 1964, 1968, 1972, 1976, 1980, 1984, 1988, 1990, 1992, 1994, 1996, 1998, 2000, 2002, 2004, 2006, 2008, 2010, 2012, 2014, 2016, 2018, 2020];
  const generalElectionDates: Array<number> = [1912, 1916, 1920, 1924, 1928, 1932, 1936, 1940, 1944, 1948, 1952, 1956, 1960, 1964, 1968, 1972, 1976, 1980, 1984, 1988, 1990, 1992, 1994, 1996, 1998, 2000, 2002, 2004, 2006, 2008, 2010, 2012, 2014, 2016];

  // Primary Election
  useEffect(() => {
    // Check If Data Exists
    if (primaryElectionData.length >= 1) {
      // All Data
      let allEligibleData: Array<number> = [];
      let allRegisteredData: Array<number> = [];

      // Iterate Over Data
      for (let votingHistory of primaryElectionData) {
        // Add To All Data
        allEligibleData.push(votingHistory.state_total_voters_eligible - votingHistory.state_total_voters_registered);
        allRegisteredData.push(votingHistory.state_total_voters_registered);
      }

      // Set Data
      setPresidentialPrimaryEligibleTurnoutData(allEligibleData);
      setPresidentialPrimaryRegisteredTurnoutData(allRegisteredData);
    }
  }, [primaryElectionData]);

  // General Election
  useEffect(() => {
    // Check If Data Exists
    if (generalElectionData.length >= 1) {
      // All Data
      let allEligibleData: Array<number> = [];
      let allRegisteredData: Array<number> = [];

      // Iterate Over Data
      for (let votingHistory of generalElectionData) {
        // Add To All Data
        allEligibleData.push(votingHistory.state_total_voters_eligible - votingHistory.state_total_voters_registered);
        allRegisteredData.push(votingHistory.state_total_voters_registered);
      }

      // Set Data
      setPresidentialGeneralEligibleTurnoutData(allEligibleData);
      setPresidentialGeneralRegisteredTurnoutData(allRegisteredData);
    }
  }, [generalElectionData]);

  // ChartJS: Voter Data
  const voterData = {
    labels: dropdownSetting === 'General Election' ? generalElectionDates : primaryElectionDates,
    datasets: [
      {
        label: 'Registered Voters',
        data: dropdownSetting === 'General Election' ? presidentialGeneralRegisteredTurnoutData : presidentialPrimaryRegisteredTurnoutData,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#007AFF',
        borderColor: '#007AFF',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#007AFF',
        pointBackgroundColor: '#007AFF',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#007AFF',
        pointHoverBorderColor: '#007AFF',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
        // Connect Gaps
        spanGaps: true
      },
      {
        label: 'Eligible Voters',
        data: dropdownSetting === 'General Election' ? presidentialGeneralEligibleTurnoutData : presidentialPrimaryEligibleTurnoutData,
        fill: false,
        lineTension: 0.1,
        // backgroundColor: 'rgba(17, 59, 94, 0.3)',
        backgroundColor: 'rgba(0, 122, 255, 0.2)',
        borderColor: '#007AFF',
        borderCapStyle: 'round',
        borderDash: [6,6],
        borderWidth: 2,
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#007AFF',
        pointBackgroundColor: '#007AFF',
        pointBorderWidth: 5,
        pointHoverRadius: 0,
        pointHoverBackgroundColor: '#007AFF',
        pointHoverBorderColor: '#007AFF',
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        // Distance Of Hover Zone
        pointHitRadius: 15,
        // Connect Gaps
        spanGaps: true,
      },
    ],
  };

  return (
    <div id="graph-container">
      <div id="graph-line-chart-title-container">
        <p id="graph-line-chart-title">Voter Registration</p>
        <p id="graph-line-chart-subtitle">|  California</p>
      </div>

      <p id="increase-text">The number of California residents who are eligible to vote vs. voters who are registered to vote.</p>

      <Form.Control
        as={'select'}
        className={'adjusted-dropdown'}
        value={dropdownSetting}
        onChange={(event) => setDropdownSetting(event.target.value)}
        style={{ backgroundColor: '#FFFFFF', marginTop: 7 }}
        custom
      >
        <option value={'General Election'}>General Election</option>
        <option value={'Primary Election'}>Primary Election</option>
      </Form.Control>

      <Bar
        data={voterData}
        height={160}
        options={{
          // maintainAspectRatio: true,
          // responsive: true,
          scaleBeginAtZero: true,
          legend: {
            position: 'bottom',
          },
          plugins: {
            datalabels: {
              display: false,
              color: '#7D7D7D',
              anchor: 'end',
              align: 'top',
              offset: 0,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                // Check If Value Exists
                if (tooltipItem.yLabel) {
                  return `${tooltipItem.yLabel.toLocaleString()} People`;
                }
              },
            },
          },
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                autoSkip: true,
              },
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                autoSkip: true,
                callback: (value: number) => {
                  // Check If Value Exists
                  if (value) {
                    return value.toLocaleString();
                  }
                },
                min: 0,
                beginAtZero: true,
                stepSize: 0,
              },
            }],
          },
        }}
      />
    </div>
  );
};

// Exports
export default CaliforniaVoterRegistration;
