// Imports: Dependencies
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

// Component: Metrics Totals
const MetricsTotals = () => {
  // React Hooks: State
  const [ electionDays, setElectionDays ] = useState<number>(0);
  const [ earlyVotingDays, setEarlyVotingDays ] = useState<number>(0);

  // React Hooks: Lifecycle Methods
  // Calculate Days Until Election
  useEffect(() => {
    // One Day (Hours * Minutes * Seconds * Milliseconds)
    const oneDay = 24 * 60 * 60 * 1000;

    // Start Date (Format: 1583910000000)
    const startDate: number = Number(new Date().setHours(0, 0, 0, 0));

    // End Date (Format: 1602486000000)
    const endDate: number = Number(new Date('11/03/2020').setHours(0, 0, 0, 0));

    // Days Until
    const daysUntil: number = Math.round((endDate - startDate) / oneDay);

    // Check If Time Is Up
    if (daysUntil > 0) {
      // Set Data
      setElectionDays(daysUntil);
    }
    else {
      // Set Data
      setElectionDays(0);
    }
  }, []);

  useEffect(() => {
    // One Day (Hours * Minutes * Seconds * Milliseconds)
    const oneDay = 24 * 60 * 60 * 1000;

    // Start Date (Format: 1583910000000)
    const startDate: number = Number(new Date().setHours(0, 0, 0, 0));

    // End Date (Format: 1602486000000)
    const endDate: number = Number(new Date('10/30/2020').setHours(0, 0, 0, 0));

    // Days Until
    const daysUntil: number = Math.round((endDate - startDate) / oneDay);

    // Check If Time Is Up
    if (daysUntil > 0) {
      // Set Data
      setEarlyVotingDays(daysUntil);
    }
    else {
      // Set Data
      setEarlyVotingDays(0);
    }
  }, []);

  return (
    <div id="metrics-totals-container">
      <div className="metrics-container">
        <p className="metrics-totals-title">Days Until Early Voting</p>
        <p className="metrics-totals-number">{earlyVotingDays ? <CountUp end={earlyVotingDays} duration={1} separator={','} /> : 0}</p>
      </div>

      <div className="metrics-container">
        <p className="metrics-totals-title">Days Until Election</p>
        <p className="metrics-totals-number">{electionDays ? <CountUp end={electionDays} duration={1} separator={','} /> : 0}</p>
      </div>
    </div>
  );
};

// Exports
export default MetricsTotals;