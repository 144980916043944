// Imports: Dependencies
import React from 'react';

// TypeScript Type: Props
interface Props {
  dataUpdatedDate: string,
};

// Component: Data Source
const DataSource = (props: Props) => {
  return (
    <div id="source-container">
      <p id="source-text">Data Source: <a id="data-source-text" href="https://www.census.gov/topics/public-sector/voting.html" target="_blank" rel="noopener noreferrer">US Census Bureau</a></p>
      <p id="source-text">Data Source: <a id="data-source-text" href="https://www.sos.ca.gov/elections/prior-elections/statewide-election-results" target="_blank" rel="noopener noreferrer">California Secretary Of State</a></p>
      <p id="source-text">Data Source: <a id="data-source-text" href="https://www.ocvote.com/datacentral/" target="_blank" rel="noopener noreferrer">Orange County Registrar Of Voters</a></p>
      <p id="last-updated-text">Last Updated: {props.dataUpdatedDate}</p>
    </div>
  );
};

// Exports
export default DataSource;
