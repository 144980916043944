// Imports: Dependencies
import React from 'react';
import { IoIosMail, IoIosCreate, IoIosPin, IoIosContact } from 'react-icons/io';

// TypeScript Type: Props
interface Props {
  buttonTitle: 'Register To Vote' | 'Voter Registration Status' | 'Polls/Ballot Drop-Off' | 'Ballot Status',
  buttonLink: string,
}

// Component: Main Button
const MainButton = (props: Props) => {
  // Render Icon
  const renderIcon = () => {
    // Register To Vote
    if (props.buttonTitle === 'Register To Vote') {
      return (
        <IoIosCreate color={'#000000'} size={26} />
      );
    }
    // Voter Registration Status
    else if (props.buttonTitle === 'Voter Registration Status') {
      return (
        <IoIosContact color={'#000000'} size={26} />
      );
    }
    // Ballot Drop-Off
    else if (props.buttonTitle === 'Polls/Ballot Drop-Off') {
      return (
        <IoIosPin color={'#000000'} size={26} />
      );
    }
    // Ballot Status
    else if (props.buttonTitle === 'Ballot Status') {
      return (
        <IoIosMail color={'#000000'} size={26} />
      );
    }
  }

  return (
    <a href={props.buttonLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <div id="main-button-container">
        <div id="main-button-icon-container">
          {renderIcon()}
        </div>

        <div id="main-button-text-container">
          <p id="main-button-text">{props.buttonTitle}</p>
        </div>
      </div>
    </a>
  );
};

// Exports
export default MainButton;
