// Imports: Dependencies
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';

// Imports: Data
const primaryElectionData = require('../data/primaryElectionData_10.14.2020.json');
const generalElectionData = require('../data/generalElectionData_10.14.2020.json');

// Component: California Ballot Type
const CaliforniaBallotType = () => {
  // React Hooks: State
  const [ dropdownSetting, setDropdownSetting ] = useState<string>('General Election');

  const [ primaryElectionPollsData, setPrimaryElectionPollsData ] = useState<Array<number>>([]);
  const [ primaryElectionMailData, setPrimaryElectionMailData ] = useState<Array<number>>([]);

  const [ generalElectionPollsData, setGeneralElectionPollsData ] = useState<Array<number>>([]);
  const [ generalElectionMailData, setGeneralElectionMailData ] = useState<Array<number>>([]);

  // Dates
  const primaryElectionDates: Array<number> = [1990, 1992, 1994, 1996, 1998, 2000, 2002, 2004, 2006, 2008, 2010, 2012, 2014, 2016, 2018, 2020];
  const generalElectionDates: Array<number> = [1990, 1992, 1994, 1996, 1998, 2000, 2002, 2004, 2006, 2008, 2010, 2012, 2014, 2016, 2018];

  // Primary Election
  useEffect(() => {
    // Check If Data Exists
    if (primaryElectionData.length >= 1) {
      // All Data
      let allPollsData: Array<number> = [];
      let allMailData: Array<number> = [];

      // Iterate Over Data
      for (let votingHistory of primaryElectionData) {
        // Check If Field Is Null
        if (votingHistory.state_voted_at_polls !== null && votingHistory.state_voted_by_mail !== null) {
          // Add To All Data
          allPollsData.push(votingHistory.state_voted_at_polls);
          allMailData.push(votingHistory.state_voted_by_mail);
        }
      }

      // Set Data
      setPrimaryElectionPollsData(allPollsData);
      setPrimaryElectionMailData(allMailData);
    }
  }, [primaryElectionData]);

  // General Election
  useEffect(() => {
    // Check If Data Exists
    if (generalElectionData.length >= 1) {
      // All Data
      let allPollsData: Array<number> = [];
      let allMailData: Array<number> = [];

      // Iterate Over Data
      for (let votingHistory of generalElectionData) {
        // Check If Field Is Null
        if (votingHistory.state_voted_at_polls !== null && votingHistory.state_voted_by_mail !== null) {
          // Add To All Data
          allPollsData.push(votingHistory.state_voted_at_polls);
          allMailData.push(votingHistory.state_voted_by_mail);
        }
      }

      // Set Data
      setGeneralElectionPollsData(allPollsData);
      setGeneralElectionMailData(allMailData);
    }
  }, [generalElectionData]);

  // ChartJS: Voter Data
  const voterData = {
    labels: dropdownSetting === 'General Election' ? generalElectionDates : primaryElectionDates,
    datasets: [
      {
        label: 'Polls',
        data: dropdownSetting === 'General Election' ? generalElectionPollsData : primaryElectionPollsData,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#113B5E',
        borderColor: '#113B5E',
        borderCapStyle: 'round',
        borderDash: [6,6],
        borderWidth: 2,
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#113B5E',
        pointBackgroundColor: '#113B5E',
        pointBorderWidth: 5,
        pointHoverRadius: 0,
        pointHoverBackgroundColor: '#113B5E',
        pointHoverBorderColor: '#113B5E',
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        // Distance Of Hover Zone
        pointHitRadius: 15,
        // Connect Gaps
        spanGaps: true,
      },
      {
        label: 'Mail In Ballots',
        data: dropdownSetting === 'General Election' ? generalElectionMailData : primaryElectionMailData,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#007AFF',
        borderColor: '#007AFF',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#007AFF',
        pointBackgroundColor: '#007AFF',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#007AFF',
        pointHoverBorderColor: '#007AFF',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
        // Connect Gaps
        spanGaps: true
      },
    ],
  };

  return (
    <div id="graph-container">
      <div id="graph-line-chart-title-container">
        <p id="graph-line-chart-title">Polls Vs. Mail In Ballots</p>
        <p id="graph-line-chart-subtitle">|  California</p>
      </div>

      <p id="increase-text">The way a California resident votes.</p>

      <Form.Control
        as={'select'}
        className={'adjusted-dropdown'}
        value={dropdownSetting}
        onChange={(event) => setDropdownSetting(event.target.value)}
        style={{ backgroundColor: '#FFFFFF', marginTop: 7 }}
        custom
      >
        <option value={'General Election'}>General Election</option>
        <option value={'Primary Election'}>Primary Election</option>
      </Form.Control>

      <Bar
        data={voterData}
        height={160}
        options={{
          // maintainAspectRatio: true,
          // responsive: true,
          scaleBeginAtZero: true,
          legend: {
            position: 'bottom',
          },
          plugins: {
            datalabels: {
              display: false,
              color: '#7D7D7D',
              anchor: 'end',
              align: 'top',
              offset: 0,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                // Check If Value Exists
                if (tooltipItem.yLabel) {
                  return `${tooltipItem.yLabel.toLocaleString()} Voters`;
                }
              },
            },
          },
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                autoSkip: true,
              },
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                autoSkip: true,
                callback: (value: number) => {
                  // Check If Value Exists
                  if (value) {
                    return value.toLocaleString();
                  }
                },
                min: 0,
                beginAtZero: true,
                stepSize: 0,
              },
            }],
          },
        }}
      />
    </div>
  );
};

// Exports
export default CaliforniaBallotType;
