// Imports: Dependencies
import React from 'react';

// Component: Main Title
const MainTitle = () => {
  return (
    <div id="title-container">
      <div id="title-button-container">
        <p id="title-text">Vote Orange County</p>
      </div>

      <p id="subtitle-text">Orange County, CA</p>
    </div>
  );
};

// Exports
export default MainTitle;
