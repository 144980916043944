// Imports: Dependencies
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';

// Imports: Data
const primaryElectionData = require('../data/primaryElectionData_10.14.2020.json');
const generalElectionData = require('../data/generalElectionData_10.14.2020.json');

// Component: Orange County Voter Percent
const OrangeCountyVoterPercent = () => {
  // React Hooks: State
  const [ dropdownSetting, setDropdownSetting ] = useState<string>('Eligible Voters');

  const [ presidentialPrimaryRegisteredData, setPresidentialPrimaryRegisteredData ] = useState<Array<number>>([]);
  const [ presidentialPrimaryEligibleData, setPresidentialPrimaryEligibleData ] = useState<Array<number>>([]);

  const [ presidentialGeneralRegisteredData, setPresidentialGeneralRegisteredData ] = useState<Array<number>>([]);
  const [ presidentialGeneralEligibleData, setPresidentialGeneralEligibleData ] = useState<Array<number>>([]);

  // Dates
  const dates: Array<number> = [1990, 1992, 1994, 1996, 1998, 2000, 2002, 2004, 2006, 2008, 2010, 2012, 2014, 2016, 2018, 2020];

  // Primary Election
  useEffect(() => {
    // Check If Data Exists
    if (primaryElectionData.length >= 1) {
      // All Data
      let allRegisteredData: Array<number | null> = [null];
      let allEligibleData: Array<number | null> = [null];

      // Iterate Over Data
      for (let votingHistory of primaryElectionData) {
        // Check If Field Is Null
        if (votingHistory.counties.orange.percent_voted_eligible !== null && votingHistory.counties.orange.percent_voted_registered !== null) {
          // Add To All Data
          allEligibleData.push(votingHistory.counties.orange.percent_voted_eligible);
          allRegisteredData.push(votingHistory.counties.orange.percent_voted_registered);
        }
      }

      // Set Data
      setPresidentialPrimaryRegisteredData(allRegisteredData);
      setPresidentialPrimaryEligibleData(allEligibleData)
    }
  }, [primaryElectionData]);

  // General Election
  useEffect(() => {
    // Check If Data Exists
    if (generalElectionData.length >= 1) {
      // All Data
      let allRegisteredData: Array<number> = [];
      let allEligibleData: Array<number> = [];

      // Iterate Over Data
      for (let votingHistory of generalElectionData) {
        // Check If Field Is Null
        if (votingHistory.counties.orange.percent_voted_eligible !== null && votingHistory.counties.orange.percent_voted_registered !== null) {
          // Add To All Data
          allEligibleData.push(votingHistory.counties.orange.percent_voted_eligible);
          allRegisteredData.push(votingHistory.counties.orange.percent_voted_registered);
        }
      }

      // Set Data
      setPresidentialGeneralRegisteredData(allRegisteredData);
      setPresidentialGeneralEligibleData(allEligibleData)
    }
  }, [generalElectionData]);

  // ChartJS: Voter Data
  const voterData = {
    labels: dates,
    datasets: [
      {
        label: 'Primary Election',
        data: dropdownSetting === 'Eligible Voters' ? presidentialPrimaryEligibleData : presidentialPrimaryRegisteredData,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#113B5E',
        borderColor: '#113B5E',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#113B5E',
        pointBackgroundColor: '#113B5E',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#113B5E',
        pointHoverBorderColor: '#113B5E',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: 'General Election',
        data: dropdownSetting === 'Eligible Voters' ? presidentialGeneralEligibleData : presidentialGeneralRegisteredData,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#007AFF',
        borderColor: '#007AFF',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#007AFF',
        pointBackgroundColor: '#007AFF',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#007AFF',
        pointHoverBorderColor: '#007AFF',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
    ],
  };

  return (
    <div id="graph-container">
      <div id="graph-line-chart-title-container">
        <p id="graph-line-chart-title">Voter Percent</p>
        <p id="graph-line-chart-subtitle">| Orange County</p>
      </div>

      <p id="increase-text">The percentage of Orange County residents that voted.</p>

      <Form.Control
        as={'select'}
        className={'adjusted-dropdown'}
        value={dropdownSetting}
        onChange={(event) => setDropdownSetting(event.target.value)}
        style={{ backgroundColor: '#FFFFFF', marginTop: 7 }}
        custom
      >
        <option value={'Eligible Voters'}>Eligible Voters</option>
        <option value={'Registered Voters'}>Registered Voters</option>
      </Form.Control>

      <Line
        data={voterData}
        height={160}
        options={{
          // maintainAspectRatio: true,
          // responsive: true,
          scaleBeginAtZero: true,
          legend: {
            position: 'bottom',
          },
          plugins: {
            datalabels: {
              display: false,
              color: '#7D7D7D',
              anchor: 'end',
              align: 'top',
              offset: 0,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                // Check If Value Exists
                if (tooltipItem.yLabel) {
                  return `${tooltipItem.yLabel}% Voted`;
                }
              },
            },
          },
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: true,
              },
            }],
            yAxes: [{
              ticks: {
                autoSkip: true,
                callback: (value: number) => {
                  // Check If Value Exists
                  if (value) {
                    return `${value}%`;
                  }
                },
                min: 0,
                beginAtZero: true,
                stepSize: 0,
              },
            }],
          },
        }}
      />
    </div>
  );
};

// Exports
export default OrangeCountyVoterPercent;
