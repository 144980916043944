// Imports: Dependencies
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';

// Imports: Data
const studentVoterData = require('../data/studentVoterData_10.15.2020.json');

// Component: California Student Voter Form Registration
const CaliforniaStudentVoterFormRegistration = () => {
  // React Hooks: State
  const [ dates, setDates ] = useState<Array<number>>([]);
  const [ registrationFormsDistributedData, setRegistrationFormsDistributedData ] = useState<Array<number>>([]);
  const [ registrationFormsReceivedData, setRegistrationFormsReceivedData ] = useState<Array<number>>([]);

  // React Hooks: Lifecycle Methods
  useEffect(() => {
    // Check If Data Exists
    if (studentVoterData.length >= 1) {
      // All Dates
      let allDates: Array<number> = [];

      // All Data
      let allFormsDistributedData: Array<number> = [];
      let allFormsReceivedData: Array<number> = [];

      // Iterate Over Data
      for (let year of studentVoterData) {
        // Add To All Dates
        allDates.push(year.year);

        // Add To All Data
        allFormsDistributedData.push(year.registration_forms_distributed);
        allFormsReceivedData.push(year.registration_forms_received);
      }

      // Set Dates
      setDates(allDates);

      // Set Data
      setRegistrationFormsDistributedData(allFormsDistributedData);
      setRegistrationFormsReceivedData(allFormsReceivedData);
    }
  }, [studentVoterData]);

  // ChartJS: Voter Data
  const voterData = {
    labels: dates,
    datasets: [
      {
        label: 'Distributed Registration Forms',
        data: registrationFormsDistributedData,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#113B5E',
        borderColor: '#113B5E',
        borderCapStyle: 'butt',
        borderDash: [6,6],
        borderWidth: 3,
        // borderCapStyle : 'round',
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#113B5E',
        pointBackgroundColor: '#113B5E',
        pointBorderWidth: 5,
        pointHoverRadius: 0,
        pointHoverBackgroundColor: '#113B5E',
        pointHoverBorderColor: '#113B5E',
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: 'Completed Registration',
        data: registrationFormsReceivedData,
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba()',
        borderColor: '#113B5E',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#113B5E',
        pointBackgroundColor: '#113B5E',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#113B5E',
        pointHoverBorderColor: '#113B5E',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
    ],
  };

  return (
    <div id="graph-container">
      <div id="graph-line-chart-title-container">
        <p id="graph-line-chart-title">Student Voter Registration By Form</p>
        <p id="graph-line-chart-subtitle">|  California</p>
      </div>

      <p id="increase-text">Voter Registration Forms distributed to California students in high school, community college, CSU, UC, and private universities.</p>

      <Line
        data={voterData}
        height={160}
        options={{
          // maintainAspectRatio: true,
          // responsive: true,
          scaleBeginAtZero: true,
          legend: {
            position: 'bottom',
          },
          plugins: {
            datalabels: {
              display: false,
              color: '#7D7D7D',
              anchor: 'end',
              align: 'top',
              offset: 0,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                // Check If Value Exists
                if (tooltipItem.yLabel) {
                  return tooltipItem.yLabel.toLocaleString();
                }
              },
            },
          },
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: true,
              },
            }],
            yAxes: [{
              ticks: {
                autoSkip: true,
                callback: (value: number) => {
                  // Check If Value Exists
                  if (value) {
                    return value.toLocaleString();
                  }
                },
                min: 0,
                beginAtZero: true,
                stepSize: 0,
              },
            }],
          },
        }}
      />
    </div>
  );
};

// Exports
export default CaliforniaStudentVoterFormRegistration;
