// Imports: Dependencies
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';

// Imports: Data
const primaryElectionData = require('../data/primaryElectionData_10.14.2020.json');
const generalElectionData = require('../data/generalElectionData_10.14.2020.json');

// Component: California Did Not Register
const CaliforniaDidNotRegister = () => {
  // React Hooks: State
  const [ presidentialPrimaryData, setPresidentialPrimaryData ] = useState<Array<number>>([]);
  const [ presidentialGeneralData, setPresidentialGeneralData ] = useState<Array<number>>([]);

  // Dates
  const dates: Array<number> = [1912, 1916, 1920, 1924, 1928, 1932, 1936, 1940, 1944, 1948, 1952, 1956, 1960, 1964, 1968, 1972, 1976, 1980, 1984, 1988, 1990, 1992, 1994, 1996, 1998, 2000, 2002, 2004, 2006, 2008, 2010, 2012, 2014, 2016, 2018, 2020];

  // Primary Election
  useEffect(() => {
    // Check If Data Exists
    if (primaryElectionData.length >= 1) {
      // All Data
      let allData: Array<number | null> = [null];

      // Iterate Over Data
      for (let votingHistory of primaryElectionData) {
        // Check If Field Is Null
        if (votingHistory.state_total_voters_eligible !== null && votingHistory.state_total_voters_registered !== null) {
          // Add To All Data
          allData.push(votingHistory.state_total_voters_eligible - votingHistory.state_total_voters_registered);
        }
      }

      // Set Data
      setPresidentialPrimaryData(allData);
    }
  }, [primaryElectionData]);

  // General Election
  useEffect(() => {
    // Check If Data Exists
    if (generalElectionData.length >= 1) {
      // All Data
      let allData: Array<number> = [];

      // Iterate Over Data
      for (let votingHistory of generalElectionData) {
        // Check If Field Is Null
        if (votingHistory.state_total_voters_eligible !== null && votingHistory.state_total_voters_registered !== null) {
          // Add To All Data
          allData.push(votingHistory.state_total_voters_eligible - votingHistory.state_total_voters_registered);
        }
      }

      // Set Data
      setPresidentialGeneralData(allData);
    }
  }, [generalElectionData]);

  // ChartJS: Voter Data
  const voterData = {
    labels: dates,
    datasets: [
      {
        label: 'Primary Election',
        data: presidentialPrimaryData,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#113B5E',
        borderColor: '#113B5E',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#113B5E',
        pointBackgroundColor: '#113B5E',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#113B5E',
        pointHoverBorderColor: '#113B5E',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: 'General Election',
        data: presidentialGeneralData,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#007AFF',
        borderColor: '#007AFF',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#007AFF',
        pointBackgroundColor: '#007AFF',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#007AFF',
        pointHoverBorderColor: '#007AFF',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
    ],
  };

  return (
    <div id="graph-container">
      <div id="graph-line-chart-title-container">
        <p id="graph-line-chart-title">Eligible Voters Not Registered To Vote</p>
        <p id="graph-line-chart-subtitle">|  California</p>
      </div>

      <p id="increase-text">The number of California residents who are Eligible Voters, but did not register to vote.</p>

      <Line
        data={voterData}
        height={160}
        options={{
          // maintainAspectRatio: true,
          // responsive: true,
          scaleBeginAtZero: true,
          legend: {
            position: 'bottom',
          },
          plugins: {
            datalabels: {
              display: false,
              color: '#7D7D7D',
              anchor: 'end',
              align: 'top',
              offset: 0,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                // Check If Value Exists
                if (tooltipItem.yLabel) {
                  return `${tooltipItem.yLabel.toLocaleString()} People`;
                }
              },
            },
          },
          annotation: {
            annotations: [
              {
                type: 'line',
                // optional annotation ID (must be unique)
                id: 'a-line-1',
                // set to 'vertical' to draw a vertical line
                mode: 'vertical',
                // ID of the scale to bind onto
                scaleID: 'x-axis-0',
                // Data value to draw the line at
                value: 1972,
                // Optional value at which the line draw should end
                //endValue: 152,
                // Line color
                borderColor: '#000000',
                // Line width
                borderWidth: 2,
                // Line dash
                // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
                borderDash: [2, 2],
                // Line Dash Offset
                // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
                borderDashOffset: 5,
                label: {
                  enabled: true,
                  content: 'Voting Age Lowered From 21 To 18',
                },
              },
            ],
          },
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: true,
              },
            }],
            yAxes: [{
              ticks: {
                autoSkip: true,
                callback: (value: number) => {
                  // Check If Value Exists
                  if (value) {
                    return value.toLocaleString();
                  }
                },
                min: 0,
                beginAtZero: true,
                stepSize: 0,
              },
            }],
          },
        }}
      />
    </div>
  );
};

// Exports
export default CaliforniaDidNotRegister;
