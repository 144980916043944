// Imports: Dependencies
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';

// Imports: Data
const ballotsReceivedByDay = require('../data/ballotsReceivedByDay_10.15.2020.json');

// Component: Orange County Mail In Ballots Received (Cumulative)
const OrangeCountyMailInBallotsReceivedCumulative = () => {
  // React Hooks: State
  const [ labels, setLabels ] = useState<Array<string>>([]);

  const [ data2020, setData2020 ] = useState<Array<number>>([]);
  const [ data2018, setData2018 ] = useState<Array<number>>([]);
  const [ data2016, setData2016 ] = useState<Array<number>>([]);
  const [ data2014, setData2014 ] = useState<Array<number>>([]);

  const [ difference, setDifference ] = useState<string>('');

  // React Hooks: Lifecycle Methods
  useEffect(() => {
    // Check If Data Exists
    if (ballotsReceivedByDay.length >= 1) {
      // All Labels
      let allLabels: Array<string> = [];

      // All Data
      let all2020Data: Array<number> = [];
      let all2018Data: Array<number> = [];
      let all2016Data: Array<number> = [];
      let all2014Data: Array<number> = [];

      // Iterate Over Data
      for (let ballots of ballotsReceivedByDay) {
        // 2020
        if (ballots.year === 2020) {
          // Iterate Over Data
          for (let dayBallots of ballots.days_received) {
            // Add To All Labels
            allLabels.push(dayBallots.days_before_election);

            // Add To All Data
            all2020Data.push(dayBallots.ballots_received);
          }
        }
         // 2018
        if (ballots.year === 2018) {
          // Iterate Over Data
          for (let dayBallots of ballots.days_received) {
            // Add To All Data
            all2018Data.push(dayBallots.ballots_received);
          }
        }
        // 2016
        if (ballots.year === 2016) {
          // Iterate Over Data
          for (let dayBallots of ballots.days_received) {
            // Add To All Data
            all2016Data.push(dayBallots.ballots_received);
          }
        }
        // 2014
        if (ballots.year === 2014) {
          // Iterate Over Data
          for (let dayBallots of ballots.days_received) {
            // Add To All Data
            all2014Data.push(dayBallots.ballots_received);
          }
        }
      }

      // Set Labels
      setLabels(allLabels);

      // Set Data
      setData2020(all2020Data);
      setData2018(all2018Data);
      setData2016(all2016Data);
      setData2014(all2014Data);
      setDifference((all2020Data[all2020Data.length - 1] / all2016Data[all2016Data.length - 1]).toFixed(1));
    }
  }, [ballotsReceivedByDay]);

  // ChartJS: Voter Data
  const voterData = {
    labels: labels,
    datasets: [
      {
        label: '2020 Presidential Election',
        data: data2020,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#007AFF',
        borderColor: '#007AFF',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#007AFF',
        pointBackgroundColor: '#007AFF',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#007AFF',
        pointHoverBorderColor: '#007AFF',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: '2018 Midterm Election',
        data: data2018,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#4E78A0',
        borderColor: '#4E78A0',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#4E78A0',
        pointBackgroundColor: '#4E78A0',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#4E78A0',
        pointHoverBorderColor: '#4E78A0',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: '2016 Presidential Election',
        data: data2016,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#113B5E',
        borderColor: '#113B5E',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#113B5E',
        pointBackgroundColor: '#113B5E',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#113B5E',
        pointHoverBorderColor: '#113B5E',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: '2014 Midterm Election',
        data: data2014,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#009ACD',
        borderColor: '#009ACD',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#009ACD',
        pointBackgroundColor: '#009ACD',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#009ACD',
        pointHoverBorderColor: '#009ACD',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
    ],
  };

  return (
    <div id="graph-container">
      <div id="graph-line-chart-title-container">
        <p id="graph-line-chart-title">Cumulative Mail In Ballots Received</p>
        <p id="graph-line-chart-subtitle">| Orange County</p>
      </div>

      <p id="increase-text">The Orange County Registrar of Voters has received {difference} times more Mail In Ballots than in the 2016 Presidential Election.</p>

      <Line
        data={voterData}
        height={160}
        options={{
          // maintainAspectRatio: true,
          // responsive: true,
          scaleBeginAtZero: true,
          legend: {
            position: 'bottom',
          },
          plugins: {
            datalabels: {
              display: false,
              color: '#7D7D7D',
              anchor: 'end',
              align: 'top',
              offset: 0,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                // Check If Value Exists
                if (tooltipItem.yLabel) {
                  return `${tooltipItem.yLabel.toLocaleString()} Ballots`;
                }
              },
            },
          },
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: true,
              },
            }],
            yAxes: [{
              ticks: {
                autoSkip: true,
                callback: (value: number) => {
                  // Check If Value Exists
                  if (value) {
                    return value.toLocaleString();
                  }
                },
                min: 0,
                beginAtZero: true,
                stepSize: 0,
              },
            }],
          },
        }}
      />
    </div>
  );
};

// Exports
export default OrangeCountyMailInBallotsReceivedCumulative;
