// Imports: Dependencies
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

// Imports: Components
// Header
import MainTitle from '../components/MainTitle';
import MetricsTotals from '../components/MetricsTotals';
import VotingFacts from '../components/VotingFacts';
import VotingLinks from '../components/VotingLinks';
import DataSource from '../components/DataSource';
import CategoryTabs from '../components/CategoryTabs';
// Footer
import Footer from '../components/Footer';
// United States
import UnitedStatesVoterNumber from '../components/UnitedStatesVoterNumber';
import UnitedStatesVoterPercent from '../components/UnitedStatesVoterPercent';
import UnitedStatesAutomaticVoterRegistration from '../components/UnitedStatesAutomaticVoterRegistration';
import UnitedStatesSameDayVoterRegistration from '../components/UnitedStatesSameDayVoterRegistration';
// California
import CaliforniaVoterNumber from '../components/CaliforniaVoterNumber';
import CaliforniaVoterPercent from '../components/CaliforniaVoterPercent';
import CaliforniaVoterRegistration from '../components/CaliforniaVoterRegistration';
import CaliforniaDidNotRegister from '../components/CaliforniaDidNotRegister';
import CaliforniaStudentVoterRegistrationType from '../components/CaliforniaStudentVoterRegistrationType';
import CaliforniaStudentVoterFormRegistration from '../components/CaliforniaStudentVoterFormRegistration';
import CaliforniaBallotType from '../components/CaliforniaBallotType';
import CaliforniaPoliticalPartyRegistration from '../components/CaliforniaPoliticalPartyRegistration';
// Orange County
import OrangeCountyVoterNumber from '../components/OrangeCountyVoterNumber';
import OrangeCountyVoterPercent from '../components/OrangeCountyVoterPercent';
import OrangeCountyDidNotRegister from '../components/OrangeCountyDidNotRegister';
import OrangeCountyBallotType from '../components/OrangeCountyBallotType';
import OrangeCountyMailInBallotsReceivedCumulative from '../components/OrangeCountyMailInBallotsReceivedCumulative';
import OrangeCountyMailInBallotsReceivedDaily from '../components/OrangeCountyMailInBallotsReceivedDaily';

// Page: Home
const Home = () => {
  // React Hooks: State
  const [ selectedTab, setSelectedTab ] = useState<'Voting' | 'Registration' | 'Ballots'>('Voting');

  // Render Tabs
  const renderTabs = () => {
    // Selected Tab: Voting
    if (selectedTab === 'Voting') {
      return (
        <>
          <OrangeCountyVoterPercent />

          <CaliforniaVoterPercent />

          <UnitedStatesVoterPercent />

          <OrangeCountyVoterNumber />

          <CaliforniaVoterNumber />

          <UnitedStatesVoterNumber />
        </>
      );
    }
    // Selected Tab: Registration
    else if (selectedTab === 'Registration') {
      return (
        <>
          <CaliforniaVoterRegistration />

          <OrangeCountyDidNotRegister />

          <CaliforniaDidNotRegister />

          <CaliforniaPoliticalPartyRegistration />

          <UnitedStatesAutomaticVoterRegistration />

          <UnitedStatesSameDayVoterRegistration />

          <CaliforniaStudentVoterRegistrationType />

          <CaliforniaStudentVoterFormRegistration />
        </>
      );
    }
    // Selected Tab: Ballots
    else if (selectedTab === 'Ballots') {
      return (
        <>
          <OrangeCountyMailInBallotsReceivedCumulative />

          <OrangeCountyMailInBallotsReceivedDaily />

          <OrangeCountyBallotType />

          <CaliforniaBallotType />
        </>
      );
    }
    else {
      console.error('Error: Invalid Category Tab selected (selectedTab)');
    }
  };

  return (
    <Container id="home-container">
      <MainTitle />

      <VotingLinks />

      <MetricsTotals />

      <VotingFacts />

      <DataSource
        dataUpdatedDate={new Date().toLocaleDateString()}
      />

      <CategoryTabs
        currentCategoryTab={(categoryTab: 'Voting' | 'Registration' | 'Ballots') => setSelectedTab(categoryTab)}
      />

      <>{renderTabs()}</>

      <Footer />
    </Container>
  );
};

// Exports
export default Home;
