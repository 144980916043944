// Imports: Dependencies
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';

// Imports: Data
const politicalPartyRegistration = require('../data/californiaPoliticalPartyRegistration_10.20.2020.json');

// Component: California Political Party Registration
const CaliforniaPoliticalPartyRegistration = () => {
  // React Hooks: State
  const [ dates, setDates ] = useState<Array<number>>([]);
  const [ registrationDemocrat, setRegistrationDemocrat ] = useState<Array<number>>([]);
  const [ registrationRepublican, setRegistrationRepublican ] = useState<Array<number>>([]);
  const [ registrationOther, setRegistrationOther ] = useState<Array<number>>([]);

  // Primary Election
  useEffect(() => {
    // Check If Data Exists
    if (politicalPartyRegistration.length >= 1) {
      // All Dates
      let allDates: Array<number> = [];

      // All Data
      let allDemocratData: Array<number> = [];
      let allRepublicanData: Array<number> = [];
      let allOtherData: Array<number> = [];

      // Iterate Over Data
      for (let registrationHistory of politicalPartyRegistration) {
        // Add To All Dates
        allDates.push(registrationHistory.year);

        // Add To All Data
        allDemocratData.push(registrationHistory.registration_democrat);
        allRepublicanData.push(registrationHistory.registration_republican);
        allOtherData.push(registrationHistory.registration_other);
      }

      // Set Dates
      setDates(allDates);

      // Set Data
      setRegistrationDemocrat(allDemocratData);
      setRegistrationRepublican(allRepublicanData);
      setRegistrationOther(allOtherData);
    }
  }, [politicalPartyRegistration]);



  // ChartJS: Voter Data
  const voterData = {
    labels: dates,
    datasets: [
      {
        label: 'Democrat',
        data: registrationDemocrat,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#007AFF',
        borderColor: '#007AFF',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#007AFF',
        pointBackgroundColor: '#007AFF',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#007AFF',
        pointHoverBorderColor: '#007AFF',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: 'Republican',
        data: registrationRepublican,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#FC3D39',
        borderColor: '#FC3D39',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#FC3D39',
        pointBackgroundColor: '#FC3D39',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#FC3D39',
        pointHoverBorderColor: '#FC3D39',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: 'Other',
        data: registrationOther,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#113B5E',
        borderColor: '#113B5E',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#113B5E',
        pointBackgroundColor: '#113B5E',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#113B5E',
        pointHoverBorderColor: '#113B5E',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
    ],
  };

  return (
    <div id="graph-container">
      <div id="graph-line-chart-title-container">
        <p id="graph-line-chart-title">Registration By Political Party</p>
        <p id="graph-line-chart-subtitle">| California</p>
      </div>

      <p id="increase-text">The political party a California resident, who is registered to vote, identifies with.</p>

      <Line
        data={voterData}
        height={160}
        options={{
          // maintainAspectRatio: true,
          // responsive: true,
          scaleBeginAtZero: true,
          legend: {
            position: 'bottom',
          },
          plugins: {
            datalabels: {
              display: false,
              color: '#7D7D7D',
              anchor: 'end',
              align: 'top',
              offset: 0,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                // Check If Value Exists
                if (tooltipItem.yLabel) {
                  return `${tooltipItem.yLabel.toLocaleString()} Registered`;
                }
              },
            },
          },
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: true,
              },
            }],
            yAxes: [{
              ticks: {
                autoSkip: true,
                callback: (value: number) => {
                  // Check If Value Exists
                  if (value) {
                    return value.toLocaleString();
                  }
                },
                min: 0,
                beginAtZero: true,
                stepSize: 0,
              },
            }],
          },
        }}
      />
    </div>
  );
};

// Exports
export default CaliforniaPoliticalPartyRegistration;
