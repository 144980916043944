// Imports: Dependencies
import React from 'react'
import { geoCentroid } from 'd3-geo';
import { ComposableMap, Geographies, Geography, Marker, Annotation } from 'react-simple-maps';

// Imports: All States
import allStates from '../data/allStates.json';

// Component: Automatic Voter Registration (United States)
const UnitedStatesAutomaticVoterRegistration = () => {
  // Geo URL
  const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

  // Small State Offsets
  const smallStateOffsets = {
    VT: [50, -8],
    NH: [34, 2],
    MA: [30, -1],
    RI: [28, 2],
    CT: [35, 10],
    NJ: [34, 1],
    DE: [33, 0],
    MD: [47, 10],
    DC: [49, 21]
  };

  // Automatic Registration States
  const automaticRegistrationStates = [
    '02', // Alaska (Ak)
    '06', // California (CA)
    '08', // Colorado (CO)
    '09', // Connecticut (CT)
    '11', // District of Colombia (DC)
    '13', // Georgia (GA)
    '17', // Illinois (IL)
    '23', // Maine (ME)
    '24', // Maryland (MD)
    '25', // Massachusetts (MA)
    '26', // Michigan (MI)
    '34', // New Jersey (NJ)
    '35', // New Mexico (NM)
    '32', // Nevada (NV)
    '41', // Oregon (OR)
    '44', // Rhode Island (RI)
    '50', // Vermont (VT)
    '51', // Virginia (VA)
    '53', // Washington (WA)
    '54', // West Virginia (WA)
  ];

  return (
    <div id="graph-container">
      <div id="graph-line-chart-title-container">
        <p id="graph-line-chart-title">Automatic Voter Registration</p>
      </div>

      <div id="usa-map-subtitle-container">
        <p id="increase-text">{automaticRegistrationStates.length} States have some form of automatic voter registration.</p>
      </div>

      <ComposableMap projection="geoAlbersUsa" id="usa-map-container">
        <Geographies geography={geoUrl}>
          {({ geographies }) => (
            <>
              {geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  stroke="#FFFFFF"
                  geography={geo}
                  fill={automaticRegistrationStates.includes(geo.id) ? '#007AFF': '#DDDDDD'}
                />
              ))}
              {geographies.map((geo) => {
                const centroid = geoCentroid(geo);
                const cur = allStates.find((s) => s.val === geo.id);
                return (
                  <g key={geo.rsmKey + '-name'}>
                    {cur &&
                      centroid[0] > -160 &&
                      centroid[0] < -67 &&
                      (Object.keys(smallStateOffsets).indexOf(cur.id) === -1 ? (
                        <Marker coordinates={centroid}>
                          <text y="2" fontSize={9} textAnchor="middle">
                            {cur.id}
                          </text>
                        </Marker>
                      ) : (
                        <Annotation
                          subject={centroid}
                          dx={smallStateOffsets[cur.id][0]}
                          dy={smallStateOffsets[cur.id][1]}
                        >
                          <text x={4} fontSize={9} alignmentBaseline="middle">
                            {cur.id}
                          </text>
                        </Annotation>
                      ))}
                  </g>
                );
              })}
            </>
          )}
        </Geographies>
      </ComposableMap>
    </div>
  );
};

// Exports
export default UnitedStatesAutomaticVoterRegistration;
