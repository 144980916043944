// Imports: Dependencies
import React from 'react';

// Component: Voting Facts
const VotingFacts = () => {
  return (
    <div id="virus-facts-container">
      <div className="fact-container">
        <div className="title-subtitle-container">
          <p className="fact-title">Mail In Ballots Issued</p>
          <p className="fact-subtitle">The day the Orange County Registrar Of Voters begins sending out Mail In Ballots.</p>
        </div>

        <div className="fact-number-container">
          <p className="fact-number">10/5/2020</p>
        </div>
      </div>

      <div className="fact-container">
        <div className="title-subtitle-container">
          <p className="fact-title">Voting Centers Open</p>
          <p className="fact-subtitle">The day when Voting Centers open across Orange County.</p>
        </div>

        <div className="fact-number-container">
          <p className="fact-number">10/30/2020</p>
        </div>
      </div>

      <div className="fact-container">
        <div className="title-subtitle-container">
          <p className="fact-title">General Election Day</p>
          <p className="fact-subtitle">The final day to Vote.</p>
        </div>

        <div className="fact-number-container">
          <p className="fact-number">11/3/2020</p>
        </div>
      </div>

      {/* <div className="fact-container">
        <div className="title-subtitle-container">
          <p className="fact-title">Mail In Ballots Issued</p>
          <p className="fact-subtitle">The number of mail-in ballots issued to voters.</p>
        </div>

        <div className="fact-number-container">
          <p className="fact-number">{'1,797,598'}</p>
        </div>
      </div> */}

      {/* <div className="fact-container">
        <div className="title-subtitle-container">
          <p className="fact-title">Mail In Ballots Returned</p>
          <p className="fact-subtitle">The number of mail-in ballots voters have returned with his or her vote casted.</p>
        </div>

        <div className="fact-number-container">
          <p className="fact-number">{'135,079'}</p>
        </div>
      </div> */}

      {/* <div className="fact-container">
        <div className="title-subtitle-container">
          <p className="fact-title">Completed Mail In Ballots</p>
          <p className="fact-subtitle">The percent of mail-in ballots voters have returned with his or her vote casted.</p>
        </div>

        <div className="fact-number-container">
          <p className="fact-number">{((135079 / 1797598) * 100).toFixed(2)}%</p>
        </div>
      </div> */}
    </div>
  );
};

// Exports
export default VotingFacts;
