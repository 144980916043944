// Imports: Dependencies
import React, { useState, useEffect } from 'react';

// TypeScript Type: Props
interface Props {
  currentCategoryTab: (selectedTab: 'Voting' | 'Registration' | 'Ballots') => void,
};

// Component: Category Tabs
const CategoryTabs = (props: Props) => {
  // React Hooks: State
  const [ selectedTab, setSelectedTab ] = useState<'Voting' | 'Registration' | 'Ballots'>('Voting');

  // React Hooks: Lifecycle Methods
  useEffect(() => {
    // Pass Value To Parent Component Props
    props.currentCategoryTab(selectedTab);
  }, [props, selectedTab]);

  return (
    <div id="category-tabs-container">
      <button
        className={selectedTab === 'Voting' ? 'category-tab-item-selected' : 'category-tab-item'}
        onClick={() => setSelectedTab('Voting')}
      >
        <p className="category-tab-title">Voting</p>
      </button>

      <button
        className={selectedTab === 'Registration' ? 'category-tab-item-selected' : 'category-tab-item'}
        onClick={() => setSelectedTab('Registration')}
      >
        <p className="category-tab-title">Registration</p>
      </button>

      <button
        className={selectedTab === 'Ballots' ? 'category-tab-item-selected' : 'category-tab-item'}
        onClick={() => setSelectedTab('Ballots')}
      >
        <p className="category-tab-title">Ballots</p>
      </button>
    </div>
  );
};

// Exports
export default CategoryTabs;

