// Imports: Dependencies
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';

// Imports: Data
const studentVoterData = require('../data/studentVoterData_10.15.2020.json');

// Component: California Student Voter Registration Type
const CaliforniaStudentVoterRegistrationType = () => {
  // React Hooks: State
  const [ dates, setDates ] = useState<Array<number>>([]);
  const [ registrationFormsReceivedData, setRegistrationFormsReceivedData ] = useState<Array<number>>([]);
  const [ onlineRegistrationData, setOnlineRegistrationData ] = useState<Array<number>>([]);

  // React Hooks: Lifecycle Methods
  useEffect(() => {
    // Check If Data Exists
    if (studentVoterData.length >= 1) {
      // All Dates
      let allDates: Array<number> = [];

      // All Data
      let allFormsReceivedData: Array<number> = [];
      let allOnlineRegistrationData: Array<number> = [];

      // Iterate Over Data
      for (let year of studentVoterData) {
        // Add To All Dates
        allDates.push(year.year);

        // Add To All Data
        allFormsReceivedData.push(year.registration_forms_received);
        allOnlineRegistrationData.push(year.registration_online);
      }

      // Set Dates
      setDates(allDates);

      // Set Data
      setRegistrationFormsReceivedData(allFormsReceivedData);
      setOnlineRegistrationData(allOnlineRegistrationData);
    }
  }, [studentVoterData]);

  // ChartJS: Voter Data
  const voterData = {
    labels: dates,
    datasets: [
      {
        label: 'Registration By Form',
        data: registrationFormsReceivedData,
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba()',
        borderColor: '#113B5E',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#113B5E',
        pointBackgroundColor: '#113B5E',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#113B5E',
        pointHoverBorderColor: '#113B5E',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: 'Registered Online',
        data: onlineRegistrationData,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#007AFF',
        borderColor: '#007AFF',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#007AFF',
        pointBackgroundColor: '#007AFF',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#007AFF',
        pointHoverBorderColor: '#007AFF',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
    ],
  };

  return (
    <div id="graph-container">
      <div id="graph-line-chart-title-container">
        <p id="graph-line-chart-title">Student Voter Registration</p>
        <p id="graph-line-chart-subtitle">|  California</p>
      </div>

      <p id="increase-text">Voter Registration with California students in high school, community college, CSU, UC, and private universities. <a href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?lawCode=ELEC&division=2.&title=&part=&chapter=2.&article=3.5" target="_blank" rel="noopener noreferrer">Student Voter Registration Act of 2003</a></p>

      <Line
        data={voterData}
        height={160}
        options={{
          // maintainAspectRatio: true,
          // responsive: true,
          scaleBeginAtZero: true,
          legend: {
            position: 'bottom',
          },
          plugins: {
            datalabels: {
              display: false,
              color: '#7D7D7D',
              anchor: 'end',
              align: 'top',
              offset: 0,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                // Check If Value Exists
                if (tooltipItem.yLabel) {
                  return `${tooltipItem.yLabel.toLocaleString()} Students`;
                }
              },
            },
          },
          annotation: {
            annotations: [
              {
                type: 'line',
                // optional annotation ID (must be unique)
                id: 'a-line-1',
                // set to 'vertical' to draw a vertical line
                mode: 'vertical',
                // ID of the scale to bind onto
                scaleID: 'x-axis-0',
                // Data value to draw the line at
                value: 2012,
                // Optional value at which the line draw should end
                //endValue: 152,
                // Line color
                borderColor: '#000000',
                // Line width
                borderWidth: 2,
                // Line dash
                // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
                borderDash: [2, 2],
                // Line Dash Offset
                // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
                borderDashOffset: 5,
                label: {
                  enabled: true,
                  content: 'www.registertovote.ca.gov Launched',
                },
              },
            ],
          },
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: true,
              },
            }],
            yAxes: [{
              ticks: {
                autoSkip: true,
                callback: (value: number) => {
                  // Check If Value Exists
                  if (value) {
                    return value.toLocaleString();
                  }
                },
                min: 0,
                beginAtZero: true,
                stepSize: 0,
              },
            }],
          },
        }}
      />
    </div>
  );
};

// Exports
export default CaliforniaStudentVoterRegistrationType;
