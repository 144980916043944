// Imports: Dependencies
import React from 'react';

// Imports: Components
import MainButton from '../components/MainButton';

// Component: Voting Links
const VotingLinks = () => {
  return (
    <div id="voting-links-container">
      <div className="voting-links-row">
        <MainButton
          buttonTitle="Register To Vote"
          buttonLink="https://registertovote.ca.gov/#mainCont"
        />

        <MainButton
          buttonTitle="Polls/Ballot Drop-Off"
          buttonLink="https://ocvote.maps.arcgis.com/apps/webappviewer/index.html?id=83dcaf2e0e6f42ce854671cb7adfdebd"
        />
      </div>

      <div className="voting-links-row">
        <MainButton
          buttonTitle="Voter Registration Status"
          buttonLink="https://voterstatus.sos.ca.gov/#mainCont"
        />

        <MainButton
          buttonTitle="Ballot Status"
          buttonLink="https://ocvote.com/vlt/"
        />
      </div>
    </div>
  );
};

// Exports
export default VotingLinks;
