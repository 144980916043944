// Imports: Dependencies
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';

// Imports: Data
const unitedStatesVoterTurnoutData = require('../data/unitedStatesVoterTurnout_11.03.2020.json');

// Component: United States Voter Number
const UnitedStatesVoterNumber = () => {
  // React Hooks: State
  const [ dates, setDates ] = useState<Array<number>>([]);
  const [ data, setData ] = useState<Array<number>>([]);
  const [ voterPopulation, setVoterPopulation ] = useState<Array<number>>([]);

  // React Hooks: Lifecycle Methods
  useEffect(() => {
    // Check If Data Exists
    if (unitedStatesVoterTurnoutData.length >= 1) {
      // All Dates
      let allDates: Array<number | null> = [];

      // All Data
      let allData: Array<number | null> = [];
      let allPopulation: Array<number | null> = [];

      // Iterate Over Data
      for (let voterTurnout of unitedStatesVoterTurnoutData) {
        // Check If Field Is Null
        if (voterTurnout.voter_turnout_number !== null) {
          // Add To All Dates
          allDates.push(voterTurnout.election_year);

          // Add To All Data
          allData.push(voterTurnout.voter_turnout_number);

          // Add To All Population
          allPopulation.push(voterTurnout.voting_age_population);
        }
      }

      // Set Data
      setDates(allDates);
      setData(allData);
      setVoterPopulation(allPopulation);
    }
  }, [unitedStatesVoterTurnoutData]);


  // ChartJS: Voter Data
  const voterData = {
    labels: dates,
    datasets: [
      {
        label: 'Presidential Election',
        data: data,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#007AFF',
        borderColor: '#007AFF',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#007AFF',
        pointBackgroundColor: '#007AFF',
        pointBorderWidth: 5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#007AFF',
        pointHoverBorderColor: '#007AFF',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
      {
        label: 'Voting Age Population',
        data: voterPopulation,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#113B5E',
        borderColor: '#113B5E',
        borderCapStyle: 'butt',
        borderDash: [6,6],
        borderWidth: 3,
        // borderCapStyle : 'round',
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#113B5E',
        pointBackgroundColor: '#113B5E',
        pointBorderWidth: 5,
        pointHoverRadius: 0,
        pointHoverBackgroundColor: '#113B5E',
        pointHoverBorderColor: '#113B5E',
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        // Distance Of Hover Zone
        pointHitRadius: 15,
      },
    ],
  };

  return (
    <div id="graph-container">
      <div id="graph-line-chart-title-container">
        <p id="graph-line-chart-title">Number Of Voters</p>
        <p id="graph-line-chart-subtitle">| United States</p>
      </div>

      <p id="increase-text">The number of eligible voters in United States that voted.</p>

      <Line
        data={voterData}
        height={160}
        options={{
          // maintainAspectRatio: true,
          // responsive: true,
          scaleBeginAtZero: true,
          legend: {
            position: 'bottom',
          },
          plugins: {
            datalabels: {
              display: false,
              color: '#7D7D7D',
              anchor: 'end',
              align: 'top',
              offset: 0,
              font: {
                weight: 'normal',
                size: 10,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                // Check If Value Exists
                if (tooltipItem.yLabel) {
                  return `${tooltipItem.yLabel.toLocaleString()} Voters`;
                }
              },
            },
          },
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: true,
              },
            }],
            yAxes: [{
              ticks: {
                autoSkip: true,
                callback: (value: number) => {
                  // Check If Value Exists
                  if (value) {
                    return value.toLocaleString();
                  }
                },
                min: 0,
                beginAtZero: true,
                stepSize: 0,
              },
            }],
          },
        }}
      />
    </div>
  );
};

// Exports
export default UnitedStatesVoterNumber;
